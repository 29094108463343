<template>
  <div>



    <!-- <div class="row"> -->
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-12">
          <div class="card-header">
          <h5 class="card-title">Participant Import</h5>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <vue-good-wizard
            ref="import-wizard"
            :steps="steps"
            :onNext="nextClicked"
            :onBack="backClicked">
            <div slot="page1">
              <h4>Load Import Data</h4>
              <p>Load the participant data you want to import.</p>
              <p>Data can be loaded from a CSV file or via an API.</p>
              <p>CSV files must include a header row.</p>
              <p>CSV the header row must EXACTLY match the header row of the template</p>
              <input id="fileUpload" type="file" hidden  @change="handleFileSelect">
              <p-button type="info" @click.prevent="chooseFiles">Choose CSV file</p-button>

              <download-excel
                  class   = "btn btn-info"
                  :isDataValid="isDataValid"
                  :getData= "getData"
                  :data   = "participantObj"
                  :exportFields = "csv_headers"
                  button_text = "Download CSV Template"
                  type    = "csv"
                  name    = "participant_template.csv">
              </download-excel>
               <p-button type="info" @click.prevent="callOrganiserAPI" :disabled=!apiURLLoaded>Call Organiser's API</p-button>

              <div class="card-header">
                <h5 class="card-title">Errors found in loaded data: {{loadedParticipantsErrorsTotal}}</h5>
              </div>
              <vue-good-table
                :theme="tableTheme"
                ref="participantsErrorImportGrid"
                :columns="errorColumns"
                :rows="loadedParticipantsErrors"
                :pagination-options="{
                  enabled: true
                  }"
                :sort-options="{
                  enabled: true,
                  }">
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                      <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                        <i class="fa fa-edit"></i>
                      </p-button>
                    </span>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
              </vue-good-table>
            </div>
            <div slot="page2">
              <h4>Format Telephone Numbers</h4>
              <p>Using the button below will format the telephone numbers in the Import Table</p>
              <p>A record's telephone number can be formatted only if there is a recognised country in the record.</p>
              <p>Once formattted use the 'Telephone Format Outcome' column filter to view the modified records.</p>

              <p-button type="info" @click.prevent="formatTelephoneNumbers">Format Telephone Numbers</p-button>

            </div>
            <div slot="page3">
              <h4 slot="header" class="title title-up">Import Data</h4>
              <p>Use the Import Participants button to import records from the data displayed in the Import Table below into {{productionNameForImport}}.</p>
              <p>Use the filter on the Import Table to refine the records you want to import. Only the records listed in the table will be imported.</p>

              <div class="row">
                <div class="col-md-3">
                  <fg-input
                    placeholder="Enter batch number"
                    label="Batch number"
                    name="batch_number"
                    v-validate="modelValidations.batch_number"
                    :error= "getError('batch_number')"
                    v-model="batch_number"
                  ></fg-input>

                  <p-button type="info" @click.prevent="importParticipantsPrompt">Import Participants</p-button>
                </div>
              </div>
            </div>
            <div slot="page4">
              <h4>Outcome</h4>
              <p>Total Successful Inserts: {{totalSuccessfulInserts}}</p>
              <p>Total Successful Updates: {{totalSuccessfulUpdates}}</p>
              <p> Total Skipped (duplicateds): {{totalSkipped}}</p>
              <p>Total Failures: {{totalFails}}</p>
            </div>
          </vue-good-wizard>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12 mt-2">
          <div class="table_body">
                  <div class="card-header">
                    <h5 class="card-title">Import Table : {{loadedParticipantsTotal}} records loaded</h5>

                  </div>
                  <vue-good-table
                    :theme="tableTheme"
                    ref="participantsImportGrid"
                    @on-column-filter="onColumnFilter"
                    :rowStyleClass="rowStyleClassFn"
                    :lineNumbers="true"
                    :columns="columns"
                    :rows="loadedParticipants"
                    :pagination-options="{
                      enabled: true,
                      mode: 'records',
                      position: 'bottom',
                      perPageDropdown: [3, 7, 9],
                      dropdownAllowAll: true,
                      nextLabel: 'next',
                      prevLabel: 'prev',
                      rowsPerPageLabel: 'Rows per page',
                      ofLabel: 'of',
                      pageLabel: 'page', // for 'pages' mode
                      allLabel: 'All',
                      }"
                    :sort-options="{
                      enabled: true,
                      }">
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'actions'">
                          <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                            <i class="fa fa-edit"></i>
                          </p-button>
                        </span>
                        <span v-else>
                          {{props.formattedRow[props.column.field]}}
                        </span>
                    </template>
                  </vue-good-table>
                </div>


        </div>
      </div>

      <!-- Modals -->
      <div>
        <modal :show.sync="modals.importDialog" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">Import Participants</h4>
          <p>This action will import {{filteredImportTotal}} records from the Import Table into: </p>
          <p><strong>{{productionNameForImport}}</strong></p>
          <p>Use the filter on the Import Table to refine the records you want to import.</p>
          <p>Do you want to proceed?</p>
          <p>{{importStatus}}</p>

          <!--
          <p>Total failed: {{totalFails}}</p>
          <p>Total inserts: {{totalSuccessfulInserts}}</p>
          <p>Total updates: {{totalSuccessfulUpdates}}</p>
          -->

          <div v-if="isLoading">
            <half-circle-spinner
              :animation-duration="1000"
              :size="60"
              color="#51bcda"
            />
          </div>

          <template slot="footer">
            <p-button type="default" @click.native="modals.importDialog = false">No</p-button>
            <p-button type="primary" @click.native="importParticipants">Yes</p-button>
          </template>
        </modal>
      </div>

      <div>
        <modal :show.sync="modals.importProgress" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">Calling Organiser API</h4>



          <p>This action may take several minutes... </p>
          <p>Production <strong>{{productionNameForImport}}</strong></p>
          <p>Total records to import: {{totalRecordsFromAPI}}</p>

          <p>Records so far: {{recordsSoFar}}</p>
          <p>{{callAPIOutcome}}</p>

          <div v-if="isLoading">
            <half-circle-spinner
              :animation-duration="1000"
              :size="60"
              color="#51bcda"
            />
          </div>

          <template slot="footer">
            <p-button type="default" @click.native="modals.importProgress = false">Close</p-button>
          </template>
        </modal>
      </div>

      <div>
        <modal :show.sync="modals.formatProgress" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">Formatting telephone numbers</h4>
          <p>Production <strong>{{productionNameForImport}}</strong></p>
          <p>Telephone numbers successfully formatted: {{successfullyFormatted}} of {{totalRecordsFromAPI}} records</p>
          <p>{{formatTelOutcome}}</p>
          <div v-if="isLoading">
            <half-circle-spinner
              :animation-duration="1000"
              :size="60"
              color="#51bcda"
            />
          </div>
          <template slot="footer">
            <p-button type="default" @click.native="modals.formatProgress = false">Close</p-button>
          </template>
        </modal>
      </div>
    </div>
  </div>

</template>
<script>
import Vue from 'vue'
import Papa from 'papaparse'
import * as CONSTS from '../../../consts/constants'
import { mapGetters } from 'vuex'
import { Card, Button, Modal } from 'src/components/UIComponents'
import { Validator } from 'vee-validate';
import axios from 'axios';
//  https://epic-spinners.epicmax.co/?ref=madewithvuejs.com#/
import { HalfCircleSpinner } from 'epic-spinners'

import  pwdgenerator from 'generate-password'

import JsonExcel from '../Accounts/JsonExcel'

Vue.component('downloadExcel', JsonExcel)

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
const PNF = require('google-libphonenumber').PhoneNumberFormat

const dictionary = {
    en: {
      attributes: {
        batch_number: 'batch number'
      }
    }
}


export default {
  computed: {
      ...mapGetters(['userSelectedProductionInt','userSelectedProductionName','userSelectedProduction', 'tableTheme', 'tableTheme'])

  },
  components: {
      Modal,
      HalfCircleSpinner
  },
  name: 'participant-import',


  created() {
    // set up a dictionary for the EN form labels
    this.$validator.localize(dictionary)
  },
  mounted(){
    this.loadExternalRefs()
    this.loadProductionAPIURL()
  },
  data(){
    return {

      modals: {
          importDialog: false,
          importProgress: false,
          formatProgress: false
      },

      isLoading: false,  // show busy indicator
      productionIntForImport: 0,  // track the production id at the time of the file import
      productionNameForImport:'',
      filteredImportTotal:0,
      batch_number: null,
      importFileName:'',
      totalFails: 0,
      totalSuccessfulInserts: 0,
      totalSuccessfulUpdates: 0,
      totalSkipped:0,
      totalRecordsFromAPI: 0,
      recordsSoFar: 0,
      callAPIOutcome: '',
      successfullyFormatted:0,
      formatTelOutcome: '',
      importStatus: '',

      reedsCompanyIds:[], // for form contact look up

      //
      // channel_name: '',
      // channel_fields: [],
      // channel_entries: [],
      // parse_header: [],
      // parse_csv: [],
      // sortOrders:{},
      // sortKey: '',
      apiURL: null,
      apiURLLoaded: false,
      //stand_types
      loadedParticipants:[],
      loadedParticipantsErrors:[],
      externalRefs: [],
      headerInCSV: false,     // if false loop through the columns to create headers
      loadedParticipantsTotal: 0,
      loadedParticipantsErrorsTotal:0,

      modelValidations: {
          batch_number : {
            required: true,
            numeric: true,
            min_value: 0
          }
      },
      participantValidations: {

          production_id: {numeric: true, required: true},
          participant_company: { required: true, max: 240 },
          participant_letter: { required: true, max: 1 },
          participant_company_original: { required: true, max: 240 },
          participant_ext_ref: { max: 65 },
          participant_stand: { max: 220 },
          participant_hall: { max: 120 },
          participant_sector: { max: 100 },
          sector_code: { max: 10 },
          participant_stand_type: { numeric: true, min_value: 0 },
          participant_sharing_status: { numeric: true, min_value: 0 },
          participant_hide_rates: { numeric: true, min_value: 0 },
          participant_address_line_1: { max: 90 },
          participant_address_line_2: { max: 140 },
          participant_address_line_3: { max: 140 },
          participant_address_town: { max: 40 },
          participant_address_county: { max: 40 },
          participant_address_post_code: { max: 40 },
          participant_address_country: { max: 30 },
          participant_telephone: { max: 55 },
          participant_fax: { max: 55 },
          participant_url: { max: 80, url: true },
          twitteraccount: { max: 120 },
          facebook: { max: 120 },
          instagram: { max: 120 },
          participant_contact: { max: 80 },
          participant_batch: { numeric: true, min_value: 0 },
          // participant_creation_date: { max: 65 },
          // participant_amended_date: null,
          participant_bought: { numeric: true, min_value: 0 },
          participant_form_contact: { max: 80 },
          participant_contact_position: { max: 90 },
          participant_email: { max: 80, email: true },
          form_contact_tel: { max: 30 },
          participant_action_outstanding: { numeric: true, min_value: 0 },
          participant_entry_complete: { numeric: true, min_value: 0 },
          participant_prods_complete: { numeric: true, min_value: 0 },
          showzone: { max: 120 },
          pavilion: { max: 120 },
          showroomurl: { max: 120, url: true },
        },

      // for template download
      participantObj:{
        'participant_ext_ref':'participant_ext_ref',
        'participant_company':'participant_company',
        'participant_address_line_1':'participant_address_line_1',
        'participant_address_line_2':'participant_address_line_2',
        'participant_address_line_3':'participant_address_line_3',
        'participant_address_town':'participant_address_town',
        'participant_address_county':'participant_address_county',
        'participant_address_post_code':'participant_address_post_code',
        'participant_address_country':'participant_address_country',
        'participant_url':'participant_url',
        'participant_email':'participant_email',
        'participant_telephone':'participant_telephone',
        'participant_fax':'participant_fax',
        'participant_mobile_number':'participant_mobile_number',
        'participant_contact':'participant_contact',
        'participant_contact_position':'participant_contact_position',
        'participant_user_name':'participant_user_name',
        'participant_web_password':'participant_web_password',
        'participant_form_contact':'participant_form_contact',
        'form_contact_email':'form_contact_email',
        'form_contact_tel':'form_contact_tel',
        'participant_stand_type':'participant_stand_type',
        'main_stand_holder_ext_ref':'main_stand_holder_ext_ref',
        'participant_stand':'participant_stand',
        'participant_hall':'participant_hall',
        'participant_sector':'participant_sector',
        'facebook':'facebook',
        'twitteraccount':'twitteraccount',
        'showzone':'showzone',
        'pavilion':'pavilion',
        'showroomurl':'showroomurl',
        'participant_text':'participant_text'
      },
      csv_headers: {
        'participant_ext_ref':'participant_ext_ref',
        'participant_company':'participant_company',
        'participant_address_line_1':'participant_address_line_1',
        'participant_address_line_2':'participant_address_line_2',
        'participant_address_line_3':'participant_address_line_3',
        'participant_address_town':'participant_address_town',
        'participant_address_county':'participant_address_county',
        'participant_address_post_code':'participant_address_post_code',
        'participant_address_country':'participant_address_country',
        'participant_url':'participant_url',
        'participant_email':'participant_email',
        'participant_telephone':'participant_telephone',
        'participant_fax':'participant_fax',
        'participant_mobile_number':'participant_mobile_number',
        'participant_contact':'participant_contact',
        'participant_contact_position':'participant_contact_position',
        'participant_user_name':'participant_user_name',
        'participant_web_password':'participant_web_password',
        'participant_form_contact':'participant_form_contact',
        'form_contact_email':'form_contact_email',
        'form_contact_tel':'form_contact_tel',
        'participant_stand_type':'participant_stand_type',
        'main_stand_holder_ext_ref':'main_stand_holder_ext_ref',
        'participant_stand':'participant_stand',
        'participant_hall':'participant_hall',
        'participant_sector':'participant_sector',
        'facebook':'facebook',
        'twitteraccount':'twitteraccount',
        'showzone':'showzone',
        'pavilion':'pavilion',
        'showroomurl':'showroomurl',
        'participant_text':'participant_text'
      },

      // table cols
      errorColumns:[
          {
            label: 'type',     // A generalization of the error
            field: 'type'
          },
	        {
            label: 'code',     // A generalization of the error
            field: 'code'
          },
          {
            label: 'message',     // A generalization of the error
            field: 'message'
          },
          {
            label: 'row',     // A generalization of the error
            field: 'row'
          }
      ],
      columns: [

          {
            field: 'operation',
            label: 'Matching Outcome',
            formatFn: this.formatMatchingOutcome,
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              filterDropdownItems: CONSTS.strings.matching_outcomes
            }
          },
          {
            field: 'valid',
            label: 'Valid Record',
            formatFn: this.formatValidRecord,
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              filterDropdownItems: CONSTS.strings.valid_record
            }
          },
          {
            field: 'server_operation_outcome',
            label: 'Import Outcome',
            formatFn: this.formatImportOutcome,
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              filterDropdownItems: CONSTS.strings.import_outcome
            }
          },
          {
            field: 'format_outcome',
            label: 'Telephone Format Outcome',
            formatFn: this.formatDateFormatOutcome,
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              filterDropdownItems: CONSTS.strings.dateformat_outcomes
            }
          },
          {
            label: 'ISO Country',
            field: 'participant_address_country_ISO'
          },
          {
            field: 'participant_ext_ref',
            label: 'External Reference',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_id',
            label: 'id'
          },
          // {
          //   field: 'arrayIndex',
          //   label: 'arrayIndex'
          // },
          {
            field: 'participant_company',
            label: 'Company Name',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_address_line_1',
            label: 'Address Line 1',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_address_line_2',
            label: 'Address Line 2',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_address_line_3',
            label: 'Address Line 3',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_address_town',
            label: 'Town',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_address_county',
            label: 'County / State',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_address_post_code',
            label: 'Post Code / Zip',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_address_country',
            label: 'Country',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_url',
            label: 'Website',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_email',
            label: 'Email',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            label: 'Original Telephone',
            field: 'participant_telephone_orig'
          },
          {
            field: 'participant_telephone',
            label: 'Telephone',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_fax',
            label: 'Fax',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_mobile_number',
            label: 'Mobile',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_contact',
            label: 'Contact',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_contact_position',
            label: 'Contact Position',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_user_name',
            label: 'User Name',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_web_password',
            label: 'User Password',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_form_contact',
            label: 'Form Contact',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'form_contact_email',
            label: 'Form Contact Email',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
           {
            field: 'form_contact_tel',
            label: 'Form Contact Telephone',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_stand_type',
            label: 'Stand Type',
            formatFn: this.formatStandType,
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              filterDropdownItems: CONSTS.strings.stand_types
            }

          },
          {
            field: 'main_stand_holder_ext_ref',
            label: 'Main Stand Holder External Reference',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_stand',
            label: 'Stand',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_hall',
            label: 'Hall',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_sector',
            label: 'Sector',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
           {
            field: 'facebook',
            label: 'Facebook',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'twitteraccount',
            label: 'Twitter',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'showzone',
            label: 'Show Zone',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'pavilion',
            label: 'Pavilion',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'showroomurl',
            label: 'Showroom URL',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },
          {
            field: 'participant_text',
            label: 'participant_text',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          }
      ],

      //
      steps: [
        {
          label: 'Load Import Data',
          slot: 'page1',
        },
        {
          label: 'Format Telephone Numbers',
          slot: 'page2',
        },
        {
          label: 'Import Data',
          slot: 'page3',
        },
        {
          label: 'Outcome',
          slot: 'page4',
          options: {
            nextDisabled: true, // control whether next is disabled or not
          },
        }
      ],
    };
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  methods: {

    onColumnFilter(params) {
       // update the filtered count
       this.filteredImportTotal = this.$refs.participantsImportGrid.filteredRows[0].children.length
       console.log(this.$refs.participantsImportGrid.totalRowCount)
    },

    chooseFiles: function() {
        document.getElementById("fileUpload").click()
    },

    nextClicked(currentPage) {
      console.log('next clicked', currentPage)
      return true; //return false if you want to prevent moving to next page
    },
    backClicked(currentPage) {
      console.log('back clicked', currentPage);
      return true; //return false if you want to prevent moving to previous page
    },

    searchArray: function(array, key, prop){
          // Optional, but fallback to key['name'] if not selected
          prop = (typeof prop === 'undefined') ? 'name' : prop;

          for (var i=0; i < array.length; i++) {
              if (array[i][prop] === key) {
                  return array[i];
              }
          }
          return null
      },
    formatStandType(value) {

       let obj = this.searchArray(CONSTS.strings.stand_types, value, 'value')
        if (obj) {
          return obj.text
        }
    },

    formatImportOutcome(value) {
       let obj = this.searchArray(CONSTS.strings.import_outcome, value, 'value')
        if (obj) {
          return obj.text
        }
    },
  formatValidRecord(value) {

       let obj = this.searchArray(CONSTS.strings.valid_record, value, 'value')
        if (obj) {
          return obj.text
        }
    },

    formatDateFormatOutcome(value) {

       let obj = this.searchArray(CONSTS.strings.dateformat_outcomes, value, 'value')
        if (obj) {
          return obj.text
        }
    },
    formatMatchingOutcome(value) {

       let obj = this.searchArray(CONSTS.strings.matching_outcomes, value, 'value')
        if (obj) {
          return obj.text
        }
    },




// console.log(number)

                // Print the phone's national number.
                // console.log(number.getNationalNumber())
                // Print the phone's raw input.
                // console.log(number.getRawInput())
                // Format number in the E164 format.
                // console.log(phoneUtil.format(number, PNF.E164))
                // Format number in the international format.
                // console.log(phoneUtil.format(number, PNF.INTERNATIONAL))


    formatTelephoneNumbers(){

      this.successfullyFormatted = 0
      this.formatTelOutcome = ''
      this.modals.formatProgress = true

      this.isLoading = true

      for (let i=0; i< this.loadedParticipants.length; i++){

        let ctry = this.loadedParticipants[i].participant_address_country
        let tel = this.loadedParticipants[i].participant_telephone
        let fax = this.loadedParticipants[i].participant_fax

        if ( ctry === null || ctry === undefined) continue

        ctry = String(ctry)
        ctry = ctry.trim()

        if (!ctry.length) continue

        // handle any oddities...
        if ( ctry === 'UK' || ctry === 'GB') ctry = 'United Kingdom'
        if ( ctry === 'USA') ctry = 'United States'

        // get the ISO code for the country
        let obj = CONSTS.strings.countries.find(obj => obj.name == ctry)

        //      { value: 0, text: 'Unmodified' },
        // { value: 1, text: 'Modified' },
        // { value: 2, text: 'Error' },
        // { value: 3, text: 'Unrecognised Country' }

        if (obj === undefined){
            // console.log('undefined ctry:', ctry)
            this.loadedParticipants[i].format_outcome = 3  // Unrecognised Country
          }else{

            this.loadedParticipants[i].participant_address_country_ISO = obj.code

            if ( tel !== null && tel !== undefined ){

              tel = String(tel)
              tel = tel.trim()

              if (tel.length){

                try{

                  let number = phoneUtil.parseAndKeepRawInput(tel, obj.code)

                  if ( phoneUtil.isValidNumber(number)){
                    this.loadedParticipants[i].participant_telephone = phoneUtil.format(number, PNF.INTERNATIONAL)

                    if (tel !== String(this.loadedParticipants[i].participant_telephone)){
                      this.loadedParticipants[i].format_outcome = 1   // modified
                      this.successfullyFormatted++
                    }
                  }
                  else {
                    // invalid number
                    this.loadedParticipants[i].format_outcome = 2  // invalid
                  }
                } catch(error){
                  // console.log('record :' + i + ' tel:' + tel)
                  this.loadedParticipants[i].format_outcome = 2  // invalid
                }
              }
            }

            if ( fax !== null && fax !== undefined ){

              fax = String(fax)
              fax = fax.trim()

              if (fax.length){
                try{

                  let number = phoneUtil.parseAndKeepRawInput(fax, obj.code)

                  if ( phoneUtil.isValidNumber(number)){
                    this.loadedParticipants[i].participant_fax = phoneUtil.format(number, PNF.INTERNATIONAL)

                    if (fax !== String(this.loadedParticipants[i].participant_fax)){
                      this.loadedParticipants[i].format_outcome = 1   // modified
                    }
                  }
                  else {
                    // invalid number
                    this.loadedParticipants[i].format_outcome = 2  // invalid
                  }
                } catch(error){
                  // console.log('record :' + i + ' fax:' + fax)
                  this.loadedParticipants[i].format_outcome = 2  // invalid
                }
              }
            }
        }
      }

      this.isLoading = false

      // spoof an array change to make it reactive in the table
      if(this.loadedParticipants.length){
        let obj = this.loadedParticipants[0]
        this.$set(this.loadedParticipants, 0, obj)
      }

      this.formatTelOutcome = 'Formatting complete.'


      this.$notify({
              message: 'Telephone numbers formatted',
              type: 'success',
              icon: 'nc-icon nc-alert-circle-i',
              horizontalAlign: 'center',
              verticalAlign: 'top'
            })
    },
    loadExternalRefs(){
        this.$http.get( 'production/' + this.userSelectedProductionInt + '/extrefs' )
        .then(response => ( this.externalRefs = response.data ))
        .catch(error => {
          // console.log(error)
          this.$notify({
            message: 'There was a problem loading the external references.',
            type: 'warning'
          })
        })
    },

    mergeExternalRefs(){

       // NEED TO ENSURE THAT THE STATES REFLECT THE CURRENTLY CHOSEN PRODUCTION - NOT A CHANGED PRODUCTION
      for (let i=0; i< this.loadedParticipants.length; i++){

        let extRef = this.loadedParticipants[i].participant_ext_ref

        if (extRef === null || extRef === undefined || String(extRef).trim().length <=0 ){
          continue
        }

        extRef = String(extRef)
        let obj = this.externalRefs.find(obj => String(obj.participant_ext_ref) === extRef)

        if (obj !== undefined){
          this.loadedParticipants[i].participant_id = parseInt(obj.participant_id)
          this.loadedParticipants[i].guid = obj.guid
          this.loadedParticipants[i].participant_creation_date = obj.participant_creation_date
          this.loadedParticipants[i].operation = 1 // update existing
        }
      }
    },

    markRecordsForImport(){
      this.filteredImportTotal = 0
      let vm = this

      for (let j=0; j< vm.loadedParticipants.length; j++){
          vm.loadedParticipants[j].import = 0  // default to no import
      }

      // loop through the filtered records and mark for import - the filtered list is not updated by the above in time to pass to the service
    for (let i=0; i< this.$refs.participantsImportGrid.filteredRows[0].children.length; i++){

        let arrayIndex = this.$refs.participantsImportGrid.filteredRows[0].children[i].arrayIndex

        for (let j=0; j< vm.loadedParticipants.length; j++){

          if (  vm.loadedParticipants[j].arrayIndex === arrayIndex ){
            if (vm.loadedParticipants[j].valid === 1){
              vm.loadedParticipants[j].import = 1
              vm.filteredImportTotal++
            }
            break
          }
        }
      }

      // if flagged to not import updates filter out updates
      if (1==1){
        for (let j=0; j< vm.loadedParticipants.length; j++){

          if (vm.loadedParticipants[j].operation == 1 ){  // update
            vm.loadedParticipants[j].import = 0  // default to no import
          }
        }
      }

    },

    importParticipantsPrompt(){



      // use the filtered rows of the import data - before the modal so it shows the correct import totals
      this.markRecordsForImport()

      if (this.productionIntForImport === 0){

        this.$notify({
              message: 'There is no production selected.',
              type: 'warning',
              icon: 'nc-icon nc-alert-circle-i',
              horizontalAlign: 'center',
              verticalAlign: 'top'
            })

         return
      }

      if ( this.filteredImportTotal > 0 ){

        this.$validator.validateAll().then(isValid => {

        if (isValid){
          this.modals.importDialog = true
        } else {
            this.isLoading = false
            this.$notify({
              message: 'Please correct the validation issues and resubmit.',
              type: 'warning'
            })
          }
        })
      } else {

        this.$notify({
              message: 'There are no records to import.',
              type: 'warning',
              icon: 'nc-icon nc-alert-circle-i',
              horizontalAlign: 'center',
              verticalAlign: 'top'
            })
      }
    },
    getError(fieldName) {
        return this.errors.first(fieldName)
    },
    importParticipants(){

      let vm = this
      this.isLoading = true

      vm.totalSkipped = vm.totalFails = vm.totalSuccessfulInserts = vm.totalSuccessfulUpdates = 0

      this.importStatus = 'Import in progress'

      for (let i=0; i< this.loadedParticipants.length; i++){

        let obj = vm.loadedParticipants[i]

        if ( obj.operation === 0){ // an insert
          obj.participant_creation_date = this.$moment()
        }

        obj.participant_amended_date = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        obj.participant_creation_date = this.$moment(obj.participant_creation_date).format('YYYY-MM-DD HH:mm:ss')
        obj.server_operation_outcome = null
        obj.participant_batch = this.batch_number
        obj.production_id = this.productionIntForImport
        obj.participant_company_original = obj.participant_company
        // obj.import = 0  // set a flag to denote which records should be imported

        if (obj.participant_user_name == null || obj.participant_user_name == ''){
          obj.participant_user_name = pwdgenerator.generate({length: 10})
        }
        if (obj.participant_web_password == null || obj.participant_web_password == ''){
          obj.participant_web_password = pwdgenerator.generate({length: 10})
        }
      }

      let postData = JSON.stringify(vm.loadedParticipants, function (key, value) {
        // turn nulls into empty strings
        return (value == null) ? "" : value
      });

      // import filtered records
      this.$http.post( '/production/participant/import', postData )
      // this.$http.post( '/production/participant/import', vm.loadedParticipants )
      // this.$http.post( '/production/participant/import', this.$refs.participantsImportGrid.filteredRows[0].children )
        .then(function (response) {

          if (response.data.apiresponse.status !== 'success'){
            vm.isLoading = false
            vm.$notify({
              message: 'There was a problem importing participants. Error code: ' + response.data.apiresponse.code,
              type: 'warning'
            })
          } else {

            // console.log(response)

            for (let i=0; i< response.data.apiresponse.reponseData.length; i++){
              let arrayIndex = response.data.apiresponse.reponseData[i].arrayIndex
                for (let j=0; j< vm.loadedParticipants.length; j++){
                  if (  vm.loadedParticipants[j].arrayIndex === arrayIndex ){
                    let obj = vm.loadedParticipants[j]
                    obj.server_operation_outcome = response.data.apiresponse.reponseData[i].server_operation_outcome
                    obj.participant_id = response.data.apiresponse.reponseData[i].participant_id

                    switch (obj.server_operation_outcome){
                      case 0: vm.totalFails++; break;
                      case 1: vm.totalSuccessfulInserts++; break;
                      case 2: vm.totalSuccessfulUpdates++; break;
                      case 3: vm.totalSkipped++; break;
                    }

                    // do this to make array changes reactive
                    // vm.$set(vm.loadedParticipants, j, obj)
                break
              }
            }
          }

          // spoof an array change to make it reactive in the table
          if(vm.loadedParticipants.length){
            let obj = vm.loadedParticipants[0]
            vm.$set(vm.loadedParticipants, 0, obj)
          }

          vm.importStatus = 'Complete'
          vm.isLoading = false

          vm.$notify({
            message: 'Import finished',
            type: 'success'
          })

          vm.modals.importDialog = false
          vm.$refs['import-wizard'].goTo(3)

        }
        })
          .catch(function (error) {
            vm.isLoading = false
            console.log(error)
            vm.$notify({
            message: 'There was a problem importing the participants. Please try again.',
            type: 'warning'
          })
        })
    },

    rowStyleClassFn(row) {
        return row.valid !== 0 ? 'validRow' : 'invalidRow'
    },

    async validateParticipants(){

      const v = new Validator();

      // console.log('validate:', this.loadedParticipants)


      for (let j=0; j< this.loadedParticipants.length; j++){

          const { valid, errors } = await v.verify(this.loadedParticipants[j].participant_company, this.participantValidations.participant_company);

          if (valid === false) {
            this.loadedParticipants[j].valid = 0

            for (let s = 0; s < errors.length; s++){
              errors[s] = errors[s].replace("{field}", "participant_company")
              this.loadedParticipantsErrors.push({
                type: "Validation Error",
                code: "",
                message: errors[s],
                row: j + 1
              })
            }
          }
      }

      // spoof an array change to make it reactive in the table
      if(this.loadedParticipants.length){
        let obj = this.loadedParticipants[0]
        this.$set(this.loadedParticipants, 0, obj)
      }
    },

    // required by the csv download
    isDataValid(){
        return true
    },
    getData(){
      let a = []
      a.push(this.participantObj)
      return a
    },

    loadProductionAPIURL(){
        this.$http.get( 'production/' + this.userSelectedProduction + '/apiurl' )
        .then(response => {
          this.apiURL = response.data.apiurl
          if (this.apiURL !== null ){
            this.apiURLLoaded = true
          } else {
            this.apiURLLoaded = false
          }
         })
        .catch(error => {
          // console.log(error)
          this.$notify({
            message: 'There was a problem loading the production API URL.',
            type: 'warning'
          })
          this.apiURLLoaded = false
        })
    },


    handleFileSelect(e) {
      var vm = this

      this.productionIntForImport = 0
      this.productionNameForImport =  ''
      this.loadedParticipantsTotal = 0
      this.loadedParticipants = []
      this.loadedParticipantsErrors = []

      // console.log(e.target.files[0])

      Papa.parse(e.target.files[0], {
        header: true,
        dynamicTyping: true,
        error:function(error, file) {
          console.log('Error occured parsing file:', error)
        },
        complete: function(results) {
          vm.loadedParticipants = results.data

          // console.log(results)

          vm.loadedParticipantsErrors = results.errors
          vm.loadedParticipantsErrorsTotal = results.errors.length

          for (let i=0; i< vm.loadedParticipants.length; i++){
            vm.loadedParticipants[i].participant_fax_orig = vm.loadedParticipants[i].participant_fax
            vm.loadedParticipants[i].participant_telephone_orig = vm.loadedParticipants[i].participant_telephone
            vm.loadedParticipants[i].participant_address_country_ISO = ''
            vm.loadedParticipants[i].format_outcome = 0
            vm.loadedParticipants[i].operation = 0 // default to an insert
            vm.loadedParticipants[i].valid = 1 // default to valid


            // set an index value
            vm.loadedParticipants[i].arrayIndex = i

          }

          // add 1 the row id to make it correspond to the table id
          for (let e=0; e< results.errors.length; e++){
            vm.loadedParticipants[results.errors[e].row].valid = 0 // set to invalid
            results.errors[e].row = results.errors[e].row + 1
          }

          // vm.loadedParticipants = JSON.stringify(vm.loadedParticipants, function (key, value) {
          //   // turn nulls into empty strings
          //   return (value == null) ? "" : value
          // });


          vm.$notify({
              message: 'File successfully loaded',
              type: 'success',
              icon: 'nc-icon nc-alert-circle-i',
              horizontalAlign: 'center',
              verticalAlign: 'top'
            })

          // track the prod id for the file
          vm.loadedParticipantsTotal = vm.loadedParticipants.length
          vm.productionIntForImport = vm.userSelectedProductionInt
          vm.productionNameForImport = vm.userSelectedProductionName

          // preprocess the data
          vm.mergeExternalRefs()
          vm.validateParticipants()
        }
      })
    },

    mapFromINFORMAData(apiResponse, offset, rpp){

      let newData = JSON.parse(apiResponse)
      this.loadedParticipants = []
      this.loadedParticipantsTotal = this.loadedParticipants.length
      let participantObjStr = JSON.stringify(this.participantObj)

      for (let l = 0; l < newData.length; l++){

        const obj = newData[l]
        const newObj = {participantObjStr}

        // console.log(obj)

        // note newObj = this.participantObj copies the reference - so just one object :-/
        // map the values
        newObj.participant_ext_ref = obj.companyid
        newObj.participant_company = obj.companyname
        newObj.participant_address_line_1 = ''
        newObj.participant_address_line_2 = ''
        newObj.participant_address_line_3 = ''
        newObj.participant_address_town = ''
        newObj.participant_address_county = ''
        newObj.participant_address_post_code = ''
        newObj.participant_address_country = ''
        newObj.participant_url = ''
        newObj.participant_email = ''
        newObj.participant_telephone = ''
        newObj.participant_fax = ''
        newObj.participant_mobile_number = ''
        newObj.participant_contact = ''
        newObj.participant_contact_position = ''
        newObj.participant_user_name = ''
        //newObj.participant_web_password =
        //newObj.participant_form_contact = obj.
        newObj.form_contact_email = ''
        newObj.form_contact_tel = ''
        newObj.participant_stand_type = 1 // main
        newObj.main_stand_holder_ext_ref = ''
        newObj.participant_stand = ''
        newObj.participant_hall = ''
        newObj.participant_sector = ''
        newObj.facebook = ''
        newObj.twitteraccount = ''
        newObj.showzone = ''
        newObj.pavilion = ''
        newObj.showroomurl = ''
        newObj.participant_text = ''

        this.loadedParticipants.push(newObj)
      }

      // default values in the loaded array
      for (let i=0; i< this.loadedParticipants.length; i++){

        this.loadedParticipants[i].participant_fax_orig = this.loadedParticipants[i].participant_fax
        this.loadedParticipants[i].participant_telephone_orig = this.loadedParticipants[i].participant_telephone
        this.loadedParticipants[i].participant_address_country_ISO = ''
        this.loadedParticipants[i].format_outcome = 0
        this.loadedParticipants[i].operation = 0 // default to an insert
        this.loadedParticipants[i].valid = 1 // default to valid
        // set an index value
        this.loadedParticipants[i].arrayIndex = i
      }

      // track the prod id for the file
      this.loadedParticipantsTotal = this.loadedParticipants.length
      this.productionIntForImport = this.userSelectedProductionInt
      this.productionNameForImport = this.userSelectedProductionName

      // preprocess the data
      this.mergeExternalRefs()
      this.validateParticipants()

      this.isLoading = false

      this.$notify({
              message: 'Data successfully loaded from API',
              type: 'success',
              icon: 'nc-icon nc-alert-circle-i',
              horizontalAlign: 'center',
              verticalAlign: 'top'
            })

    },


    loadReedsFormContacts(){

        let payload = JSON.stringify(this.reedsCompanyIds)

        this.isLoading = true

        this.$http.post( 'production/' + this.userSelectedProduction + '/api/reedsusers', payload )
        .then(response => {

          let formContacts = response.data
          let contacts = {}


          // this builds an object map
          for (let l=0;l < formContacts.length; l++){

            let fc = formContacts[l]
            let id = String(fc.companyId)
            contacts[id] = fc
          }

          // loop through and look for the external id in the form contacts obj
          for (let i=0; i< this.loadedParticipants.length; i++){

            let extRef = String(this.loadedParticipants[i].participant_ext_ref)

            let obj = contacts[  extRef ]

            // console.log( contacts[  extRef ].email)
            this.loadedParticipants[i].participant_form_contact = contacts[  extRef ].name
				    this.loadedParticipants[i].form_contact_email = contacts[  extRef ].email

          }

          this.isLoading = false

          this.callAPIOutcome = 'Complete.'

         })
        .catch(error => {
          // console.log(error)
          this.isLoading = false
          this.$notify({
            message: 'There was a problem loading the Reeds Form Contact data from the API.',
            type: 'warning'
          })
        })
    },

      // call the inform company API to get the details of all the companies
     loadReedsFormContacts(){

        let payload = JSON.stringify(this.reedsCompanyIds)

        this.isLoading = true

        this.$http.post( 'production/' + this.userSelectedProduction + '/api/informacompanies', payload )
        .then(response => {

          let formContacts = response.data
          let contacts = {}


          // this builds an object map
          for (let l=0;l < formContacts.length; l++){

            let fc = formContacts[l]
            let id = String(fc.companyId)
            contacts[id] = fc
          }

          // loop through and look for the external id in the form contacts obj
          for (let i=0; i< this.loadedParticipants.length; i++){

            let extRef = String(this.loadedParticipants[i].participant_ext_ref)

            let obj = contacts[  extRef ]

            // console.log( contacts[  extRef ].email)
            this.loadedParticipants[i].participant_form_contact = contacts[  extRef ].name
				    this.loadedParticipants[i].form_contact_email = contacts[  extRef ].email

          }

          this.isLoading = false

          this.callAPIOutcome = 'Complete.'

         })
        .catch(error => {
          // console.log(error)
          this.isLoading = false
          this.$notify({
            message: 'There was a problem loading the Reeds Form Contact data from the API.',
            type: 'warning'
          })
        })
    },


    mapFromReedsData(apiResponse, offset, rpp){

      let newData = JSON.parse(apiResponse)

      // console.log(newData)

      // reset if at start
      if ( offset === 1) {
        this.loadedParticipants = []
        this.totalRecordsFromAPI = newData.TotalRecords
      }
      this.loadedParticipantsTotal = this.loadedParticipants.length
      let participantObjStr = JSON.stringify(this.participantObj)

      for (let l = 0; l < newData.Data.length; l++){

        let printedCatalogueContactIndex = null
        let productCategoriesIndex = null

        const obj = newData.Data[l]
        const newObj = {participantObjStr}
        // note newObj = this.participantObj copies the reference - so just one object :-/

        // look for the offsets in the Extensions array for the attributes need - changes per record :-/
        for (let al = 0; al < obj.Extension.Attributes.length; al++){

          // console.log('obj.Extension.Attributes[al].DisplayName:',obj.Extension.Attributes[al].DisplayName)

          if ( obj.Extension.Attributes[al].DisplayName == "Printed Catalogue Contact"){
            printedCatalogueContactIndex = al
            // console.log('printedCatalogueContactIndex',printedCatalogueContactIndex)
          }
          if ( obj.Extension.Attributes[al].DisplayName == "Product Categories"){
            productCategoriesIndex = al
            // console.log('productCategoriesIndex',productCategoriesIndex)
          }
        }

        // map the values
        newObj.participant_ext_ref = obj.Id.Id

        // track the ids to pass to the service to get the form conacts

        this.reedsCompanyIds.push(obj.Id.Id)

        newObj.participant_company = obj.CompanyName

        // https://stackoverflow.com/questions/2631001/test-for-existence-of-nested-javascript-object-key
        // (((test || {}).level1 || {}).level2 || {}).level3;

        // console.log(obj)
        // console.log('fax', ((obj || {}).Fax || {}).en )

        if (obj.Address){
          newObj.participant_address_line_1 = obj.Address.AddressLine1.en
          newObj.participant_address_line_2 = obj.Address.AddressLine2.en
          newObj.participant_address_line_3 = obj.Address.AddressLine3.en
          newObj.participant_address_town = obj.Address.City.en
          newObj.participant_address_county = obj.Address.SubRegion.en
          newObj.participant_address_post_code = obj.Address.PostCode.en
          newObj.participant_address_country = obj.Address.Country.DisplayName
          newObj.participant_address_country_ISO = obj.Address.Country.IsoCode
        }
        newObj.participant_url = obj.ExhibitorCompanyWebsiteUrl.en
        newObj.participant_email = obj.ExhibitorCompanyEmail.en
        newObj.participant_telephone = obj.Phone.en
        newObj.participant_fax = obj.Fax.en
        newObj.participant_mobile_number = obj.MobilePhone.Invariant

        if (printedCatalogueContactIndex !== null && obj.Extension.Attributes[printedCatalogueContactIndex].Value) newObj.participant_contact = obj.Extension.Attributes[printedCatalogueContactIndex].Value.en
        newObj.participant_contact_position = ''
        newObj.participant_user_name = null
        newObj.participant_web_password = null

        // needs to be set with separate API call
        newObj.participant_form_contact = ''
        newObj.form_contact_email = ''

        newObj.form_contact_tel = ''

        if ( obj.ParentId  ){
            newObj.participant_stand_type = 2 // sharer
        } else {
            newObj.participant_stand_type = 1 // main
        }
        newObj.main_stand_holder_ext_ref = obj.ParentId

        if ( obj.StandReferences && obj.StandReferences.length > 0 ){
          newObj.participant_stand = obj.StandReferences[0]
        }

        newObj.participant_hall = ''
        newObj.participant_sector = ''
        newObj.facebook = ''
        newObj.twitteraccount = ''
        newObj.showzone = ''
        newObj.pavilion = ''
        newObj.showroomurl = ''
        newObj.participant_text = obj.PrintedDescription.en

        this.loadedParticipants.push(newObj)
      }


        this.recordsSoFar = this.loadedParticipants.length

      // if ( offset === 1) this.loadedParticipants = []
      // offset, rpp
      if ( newData.Data.length < rpp ){

        // looks like there are no more pages

        // default values in the loaded array
        for (let i=0; i< this.loadedParticipants.length; i++){

          this.loadedParticipants[i].participant_fax_orig = this.loadedParticipants[i].participant_fax
          this.loadedParticipants[i].participant_telephone_orig = this.loadedParticipants[i].participant_telephone
          this.loadedParticipants[i].participant_address_country_ISO = ''
          this.loadedParticipants[i].format_outcome = 0
          this.loadedParticipants[i].operation = 0 // default to an insert
          this.loadedParticipants[i].valid = 1 // default to valid
          // set an index value
          this.loadedParticipants[i].arrayIndex = i
        }

        // track the prod id for the file
        this.loadedParticipantsTotal = this.loadedParticipants.length
        this.productionIntForImport = this.userSelectedProductionInt
        this.productionNameForImport = this.userSelectedProductionName

        // preprocess the data
        this.mergeExternalRefs()
        this.validateParticipants()

        this.isLoading = false

        if ( this.totalRecordsFromAPI === this.loadedParticipantsTotal){

          this.$notify({
                message: this.totalRecordsFromAPI + ' records successfully loaded from API',
                type: 'success',
                icon: 'nc-icon nc-alert-circle-i',
                horizontalAlign: 'center',
                verticalAlign: 'top'
              })

              this.callAPIOutcome = 'Now fetching form contacts.'

              this.loadReedsFormContacts()

        } else {

            this.$notify({
                message: 'Expected ' + this.totalRecordsFromAPI + ' records but loaded '+this.loadedParticipantsTotal+' from API',
                type: 'warning',
                icon: 'nc-icon nc-alert-circle-i',
                horizontalAlign: 'center',
                verticalAlign: 'top'
              })

              this.callAPIOutcome = 'Expected ' + this.totalRecordsFromAPI + ' records but loaded '+this.loadedParticipantsTotal+' from API'
        }
      } else {
        // get the next page

        //  console.log ('newData.Data.length:', newData.Data.length)
        let newOffset = offset + rpp

        // console.log (newOffset, offset, rpp)
        this.callOrganiserAPIPage(newOffset, rpp)
      }
    },

    callOrganiserAPI(){

      this.isLoading = true
      this.modals.importProgress = true

      this.callAPIOutcome = ''
      this.callOrganiserAPIPage(1, 50)

    },

    callOrganiserAPIPage(offset, rpp){


      this.$http.get( 'production/' + this.userSelectedProduction + '/api?offset='+offset+'&rpp='+rpp)
        .then(response => {

          let organiserApi = response.data.organiser_api
          let apiResponse = response.data.apiresponse

          console.log('organiser API:',response.data.organiser_api)

          if (organiserApi === null || organiserApi == ''){
            this.$notify({
              message: 'The Orgaaniser API is not set in the production.',
              type: 'warning'
            })
          } else {

            switch(organiserApi){
              case 'INFORMA': this.mapFromINFORMAData(apiResponse, offset, rpp)
              break

              case 'REEDS': this.mapFromReedsData(apiResponse, offset, rpp)
              break
            }
          }

         })
        .catch(error => {
           console.log(error)
          this.callAPIOutcome = 'There was a problem calling the organiser API'

          this.$notify({
            message: 'There was a problem calling the organiser API',
            type: 'warning'
          })
        })
    },

    onCancel(){

    }
  }
};
</script>

<style lang="scss" >

  .validRow {
   color:#606266;
 }

.invalidRow {
   background-color:#ef8157;
   color:#ffffff;
 }

  .table_body{
  margin-top:  30px;
  min-height:  400px;
  margin-left:  50px;
  margin-right:  50px;
  border:  1px solid #aebac4;
  background-color: #fff;
  position: relative;
  border-radius: 5px;
  padding-bottom: 50px;
}



</style>


